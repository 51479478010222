<template>
    <span ref="container"></span>
</template>

<script>
export default {
    name: 'TypewriterText',
    props: {
        text: {
            type: String,
            required: true
        },
        speed: {
            type: Number,
            default: 50
        }
    },
    mounted() {
        this.startTyping()
    },
    methods: {
        startTyping() {
            const container = this.$refs.container
            const text = this.text
            let index = 0

            const type = () => {
                if (index < text.length) {
                    container.textContent += text.charAt(index)
                    index++
                    setTimeout(type, this.speed)
                }
            }

            type()
        }
    }
}
</script> 