<template>
    <back-button :route="{ name: 'finnancial.cashout-control' }" />
    <div class="my-3 flex flex-col">
        <user-heading
            v-if="storeCashout"
            :user="storeCashout.user"
        ></user-heading>
        <h2 class="tex text-lg font-bold capitalize dark:text-white">
            Saque #{{ storeCashout.id }}

            <cashout-tags :cashout="storeCashout" />
        </h2>
    </div>
    <div class="grid grid-cols-3 gap-4">
        <div class="col-span-2">
            <commission-cards :user="storeCashout.user"></commission-cards>
            <h3
                class="align-center flex text-lg font-bold text-gray-400"
            >
                Saldo
                <small
                    class="ml-auto text-indigo-500 hover:cursor-pointer hover:underline"
                    @click="balanceChart = !balanceChart"
                >
                    Grafico
                </small>
            </h3>
            <balance-schedule
                class="my-3"
                v-if="storeCashout && balanceChart"
                :user="storeCashout.user"
            ></balance-schedule>
            <balance-cards
                v-if="storeCashout"
                :user="storeCashout.user"
            ></balance-cards>

            <h3
                class="align-center mt-0 flex text-lg font-bold text-gray-400"
            >
                Conta

                <update-bank-account
                    v-if="storeCashout && hasPermission('update.bankAccounts')"
                    :bank-account="storeCashout.bank_account"
                    @updated="
                        fetchCashout();
                        fetchStatus();
                    "
                />
            </h3>
            <div
                class="h-50 mb-4 w-full min-w-0 overflow-hidden rounded-lg bg-white drop-shadow-sm dark:bg-gray-800"
            >
                <div
                    class="h-50 w-full min-w-0 overflow-hidden rounded-lg bg-white p-5 drop-shadow-sm dark:bg-gray-800"
                >
                    <col-skeleton
                        v-if="loading"
                        :cols="3"
                        class="mb-4"
                    ></col-skeleton>
                    <div
                        class="mb-4 grid grid-cols-4 gap-3"
                        v-if="storeCashout"
                    >
                        <info-col title="CADASTRADO EM" :loading="loading">
                            {{ storeCashout.user.created_at }}
                        </info-col>
                        <info-col title="ÚLTIMO SAQUE" :loading="loading">
                            {{ storeCashout.last_cashout }}
                        </info-col>
                        <info-col
                            title="SAQUES REALIZADOS"
                            :loading="loading"
                            class="w-full"
                        >
                            <div class="flex flex-row">
                                <div
                                    v-for="cashoutCount in storeCashout.cashout_count"
                                    v-tooltip="
                                        getCashoutStatusName(
                                            cashoutCount.status,
                                        )
                                    "
                                    class="mr-1 flex items-center"
                                >
                                    <component
                                        :is="countIcon(cashoutCount.status)"
                                        class="mr-1 h-5 w-5 flex-shrink-0 self-center"
                                    />
                                    <span>{{ cashoutCount.count }}</span>
                                </div>
                            </div>
                        </info-col>
                    </div>
                    <col-skeleton
                        v-if="loading"
                        :cols="4"
                        class="mt-5"
                    ></col-skeleton>
                    <div class="grid grid-cols-4 gap-3" v-if="storeCashout">
                        <info-col title="NOME FAVORECIDO">
                            {{ storeCashout.bank_account.holder_name }}
                        </info-col>
                        <info-col title="NATUREZA">
                            {{
                                storeCashout.user.type === "company"
                                    ? "JURÍDICA"
                                    : "FÍSICA"
                            }}
                        </info-col>
                        <info-col title="CPF/CNPJ">
                            {{
                                storeCashout.user.type === "company"
                                    ? formatCnpj(storeCashout.user.cnpj)
                                    : formatCpf(storeCashout.user.cpf)
                            }}
                        </info-col>
                        <info-col title="BANCO" v-if="storeCashout.bank_account.receiver_type === 'bank'">
                            {{
                                formatBankCode(
                                    storeCashout.bank_account?.bank?.code,
                                )
                            }}
                            -
                            {{ storeCashout.bank_account?.bank?.name }}
                        </info-col>
                        <info-col title="Chave PIX" v-if="storeCashout.bank_account.receiver_type === 'pix'">
                            {{ storeCashout.bank_account?.pix_key }}
                        </info-col>

                        <info-col title="TIPO DE CONTA" v-if="storeCashout.bank_account.receiver_type === 'bank'">
                            {{
                                storeCashout.bank_account.type === "checking"
                                    ? "Corrente"
                                    : "Poupança"
                            }}
                        </info-col>
                        <info-col title="AGENCIA" v-if="storeCashout.bank_account.receiver_type === 'bank'">
                            {{ storeCashout.bank_account.branch_number }}
                            <span
                                v-if="
                                    storeCashout.bank_account.branch_check_digit
                                "
                            >
                                -
                                {{
                                    storeCashout.bank_account.branch_check_digit
                                }}
                            </span>
                        </info-col>
                        <info-col title="CONTA" v-if="storeCashout.bank_account.receiver_type === 'bank'">
                            {{ storeCashout.bank_account.account_number }} -
                            {{ storeCashout.bank_account.account_check_digit }}
                        </info-col>
                    </div>
                </div>
            </div>
            <div
                class="h-50 mb-4 w-full min-w-0 overflow-hidden rounded-lg bg-white p-5 drop-shadow-sm dark:bg-gray-800"
            >
                <col-skeleton v-if="loading" :cols="3"></col-skeleton>
                <div class="grid grid-cols-4 gap-3" v-if="storeCashout">
                    <info-col title="VALOR SOLICITADO">
                        {{ $root.formatCurrency(storeCashout.amount) }}
                    </info-col>
                    <info-col title="TAXA APLICADA">
                        {{ $root.formatCurrency(storeCashout.fee) }}
                    </info-col>
                    <info-col title="VALOR A RECEBER">
                        {{ $root.formatCurrency(storeCashout.net_value) }}
                    </info-col>
                </div>
            </div>
            <cashout-clicks
                v-if="storeCashout"
                :cashout="storeCashout"
            ></cashout-clicks>
            <cashout-payables
                v-if="storeCashout"
                :cashout="storeCashout"
                products
            ></cashout-payables>
        </div>
        <div>
            <div class="mb-2" v-if="audit">
                <system-analysis v-if="audit" :analysis="audit?.audit.analysis" />
            </div>
            <card class="mb-2">
                <div class="flex w-full flex-col">
                    <div class="flex justify-between">
                        <span
                            class="mb-1 text-sm font-bold uppercase text-gray-400 dark:text-gray-400"
                        >
                            STATUS
                        </span>
                        <return-cashout
                            v-if="
                                storeCashout &&
                                storeCashout.status === 'approved'
                            "
                            :cashout="storeCashout"
                            @submit="fetchStatus"
                        />
                    </div>

                    <col-skeleton :cols="1" v-if="loading" />

                    <span
                        v-if="!loading"
                        :class="getCashoutStatusClass(storeCashout.status)"
                        class="w-2/5 rounded px-1 py-1 text-center text-sm text-white dark:text-gray-200"
                    >
                        {{ getCashoutStatusName(storeCashout.status) }}
                    </span>
                </div>
            </card>
            <cashout-reviews :cashout="storeCashout" />

            <transfer-status
                v-if="storeCashout"
                class="mb-3"
                :cashout="storeCashout"
            />

            <div
                class="h-50 mb-4 flex w-full min-w-0 overflow-hidden rounded-lg bg-white p-5 drop-shadow-sm dark:bg-gray-800"
            >
                <timeline-skeleton v-if="loadingStatus" />
                <ol
                    v-else
                    class="relative w-full border-l border-gray-200 dark:border-gray-700"
                >
                    <li
                        :class="{
                            'mb-5': status.user_id === storeCashout.user_id,
                            'ml-4': true,
                        }"
                        v-for="status in statuses"
                    >
                        <div
                            class="absolute -left-1.5 mt-1.5 h-3 w-3 rounded-full border border-white dark:border-gray-900"
                            :class="getStatusClass(status.status)"
                        ></div>
                        <time
                            class="mb-1 text-sm font-normal leading-none text-gray-200 text-gray-800 dark:text-gray-500"
                        >
                            {{ status.created_at }}
                        </time>
                        <h3
                            class="text-md flex items-center font-semibold text-gray-900 dark:text-white"
                        >
                            {{ getCashoutStatusName(status.status) }}
                            <CloudIcon
                                class="ml-1 h-4 h-5 w-4 w-5 text-gray-500 focus-visible:ring-0"
                                v-if="status.user_id === storeCashout.user_id"
                                v-tooltip="'API Itau'"
                            />
                        </h3>
                        <p
                            class="text-base font-normal text-gray-500 dark:text-gray-200"
                            v-if="status.user_id !== storeCashout.user_id"
                        >
                            {{ status.user.name }} {{ status.user.email }}
                        </p>
                        <p
                            v-if="
                                status.description &&
                                status.user_id !== storeCashout.user_id
                            "
                            class="mt-1 rounded-lg bg-slate-900 p-4 text-base font-normal text-gray-500 dark:text-gray-200"
                        >
                            {{ status.description }}
                        </p>
                    </li>
                    <li class="ml-4">
                        <div
                            class="absolute -left-1.5 mt-1.5 h-3 w-3 rounded-full border border-white bg-gray-200 dark:border-gray-900 dark:bg-gray-700"
                        ></div>
                        <time
                            class="mb-1 text-sm font-normal leading-none text-gray-900 dark:text-gray-500"
                        >
                            {{ storeCashout.created_at }}
                        </time>
                        <h3
                            class="text-lg font-semibold text-gray-900 dark:text-white"
                        >
                            Saque Solicitado
                        </h3>
                        <p
                            class="text-base font-normal text-gray-500 dark:text-gray-200"
                        >
                            <a
                                @click="approve = !approve"
                                class="inline-flex items-center rounded-lg border border-gray-200 bg-white px-4 py-2 text-sm font-medium text-gray-900 hover:cursor-pointer hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:text-blue-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-200 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700"
                            >
                                Alterar Status
                                <svg
                                    class="ml-2 h-3 w-3"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                                        clip-rule="evenodd"
                                    ></path>
                                </svg>
                            </a>
                        </p>
                    </li>
                </ol>
            </div>

            <TransitionRoot
                :show="approve"
                enter="transition-opacity duration-150"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="transition-opacity duration-150"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div
                    class="h-50 mb-4 w-full min-w-0 overflow-hidden rounded-lg bg-white p-5 drop-shadow-sm dark:bg-gray-800"
                >
                    <h3
                        class="text-lg font-semibold text-gray-900 dark:text-white"
                    >
                        Alterar Status
                    </h3>
                    <label
                        for="countries"
                        class="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-200"
                        >Selecione uma opção</label
                    >
                    <select
                        v-model="form.status"
                        id="countries"
                        class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                    >
                        <option :value="false" selected>Status</option>
                        <option value="sent_to_bank">Enviado ao Banco</option>
                        <option value="approved">Aprovado</option>
                        <option value="returned">Retornado</option>
                        <option value="in_audit">Em Auditoria</option>
                    </select>

                    <div v-if="form.status === 'reproved'">
                        <label
                            for="message"
                            class="mb-2 mt-2 block text-sm font-medium text-gray-900 dark:text-gray-200"
                            >Motivo</label
                        >
                        <textarea
                            v-model="form.description"
                            id="message"
                            rows="4"
                            class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                            placeholder="Your message..."
                        ></textarea>
                    </div>

                    <button
                        v-if="form.status"
                        @click.prevent="updateStatus"
                        :disabled="loading"
                        type="button"
                        :class="{ 'cursor-not-allowed': loading }"
                        class="mx-auto mt-5 inline-flex w-full items-center justify-center rounded-md bg-indigo-500 px-4 py-2 text-sm font-semibold leading-6 text-white shadow transition duration-150 ease-in-out hover:bg-indigo-400"
                    >
                        <svg
                            v-if="loading"
                            class="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                class="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                stroke-width="4"
                            ></circle>
                            <path
                                class="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                        </svg>
                        Enviar
                    </button>
                </div>
            </TransitionRoot>
        </div>
    </div>
</template>

<script>
import VueSelect from "../../../components/forms/VueSelect.vue";
import ProductStatus from "../../../components/layouts/ProductStatus.vue";
import { mapWritableState, mapActions } from "pinia";
import { Store } from "../../../stores/store.js";
import { TransitionRoot } from "@headlessui/vue";
import HelperMixin from "../../../mixins/HelperMixin.vue";
import BankMixin from "../../../mixins/BankMixin.vue";
import CashoutPayables from "./CashoutPayables.vue";
import CashoutClicks from "./CashoutClicks.vue";
import UserHeading from "../../users/UserHeading.vue";
import ReturnCashout from "./ReturnCashout.vue";
import CommissionCards from "../../../components/user/CommissionCards.vue";
import BalanceCards from "../../../components/user/BalanceCards.vue";
import BalanceSchedule from "../user/BalanceSchedule.vue";
import TransferStatus from "../../../components/cashouts/TransferStatus.vue";
import InfoCol from "../../../components/layouts/InfoCol.vue";
import ColSkeleton from "../../../components/layouts/skeleton/ColSkeleton.vue";
import BackButton from "../../../components/layouts/navigation/BackButton.vue";
import TimelineSkeleton from "../../../components/layouts/skeleton/TimelineSkeleton.vue";
import CashoutReviews from "../../../components/cashouts/reviews/CashoutReviews.vue";
import Card from "../../../components/layouts/Card.vue";
import CashoutTags from "../../../components/cashouts/CashoutTags.vue";
import {
    CloudIcon,
    ExclamationCircleIcon,
    ExclamationTriangleIcon,
    XCircleIcon,
} from "@heroicons/vue/24/outline";
import UpdateBankAccount from "../../../components/finnancial/bankAccount/UpdateBankAccount.vue";
import PermissionMixin from "../../../mixins/PermissionMixin.vue";
import { CheckCircleIcon, NoSymbolIcon } from "@heroicons/vue/24/outline";
import SystemAnalysis from "../../../components/cashouts/reviews/SystemAnalysis.vue";


export default {
    components: {
        XCircleIcon,
        CheckCircleIcon,
        ExclamationTriangleIcon,
        UpdateBankAccount,
        ExclamationCircleIcon,
        CashoutTags,
        Card,
        CashoutReviews,
        TimelineSkeleton,
        BackButton,
        ColSkeleton,
        InfoCol,
        BalanceSchedule,
        BalanceCards,
        VueSelect,
        ProductStatus,
        TransitionRoot,
        CashoutPayables,
        UserHeading,
        ReturnCashout,
        CashoutClicks,
        CommissionCards,
        TransferStatus,
        CloudIcon,
        SystemAnalysis,
    },
    mixins: [HelperMixin, BankMixin, PermissionMixin],
    data() {
        return {
            audit: false,
            approve: false,
            balanceChart: false,
            loadingStatus: false,
            statuses: [],
            tags: [],
            form: {
                status: false,
                description: "",
            },
        };
    },

    computed: {
        ...mapWritableState(Store, ["storeCashout", "authUser"]),

        loading() {
            return !this.storeCashout;
        },
    },

    mounted() {
        this.fetchCashout();
        this.fetchStatus();
        this.fetchAudit();
    },

    methods: {
        ...mapActions(Store, ["fetchCashout"]),
        countIcon(status) {
            switch (status) {
                case "approved":
                    return "CheckCircleIcon";
                case "in_audit":
                    return "ExclamationTriangleIcon";
                case "returned":
                    return "XCircleIcon";
                default:
                    return status;
            }
        },
        fetchAudit() {
            axios.get(`/api/cashout/${this.$route.params.cashout_id}/payables/details`)
                .then(({ data }) => {
                    this.audit = data;
                });
        },
        fetchStatus() {
            this.loadingStatus = true;
            axios
                .get(
                    "/api/cashout/" + this.$route.params.cashout_id + "/status",
                )
                .then(({ data }) => {
                    this.statuses = data;
                    this.loadingStatus = false;
                });
        },
        updateStatus() {
            axios
                .post(
                    "/api/cashout/" + this.$route.params.cashout_id + "/status",
                    this.form,
                )
                .then(({ data }) => {
                    this.fetchStatus();
                    this.fetchCashout();
                    this.approve = false;
                });
        },
    },
};
</script>
